<template>
  <section>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Errors</p>
        <button type="button" class="delete" @click="$parent.close()" />
      </header>
      <section class="modal-card-body">
        <div v-for="(error, index) in errors" :key="index" class="mb-5">
          <span class="font-bold">Line: {{ error.line }}:</span>
          {{ error.errorMessage }}
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button label="Close" @click.native="$parent.close()" />
      </footer>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FulfillmentImportErrors',
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
.modal-card-body {
  overflow-y: auto;
}
</style>
