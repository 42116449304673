


















































import Vue from 'vue'
import axios from 'axios'
import Errors from '@/components/fulfillment/Errors.vue'
import { getCurrentUser } from '@/lib/user'

export default Vue.extend({
  name: 'FulfillmentImport',
  components: {
    Errors,
  },
  data() {
    return {
      isLoading: true,
      errors: null as Record<string, any>[] | null,
      step: 'validation',
      isErrorsModalActive: false,
      done: false,
      linesProcessed: 0,
      linesProcessedTimestamp: null as number | null,
    }
  },
  computed: {
    formattedErrors(): string[] {
      return (this.errors || []).map(
        (error) => `CSV Line ${error.line}: ${error.errorMessage}`
      )
    },
  },
  watch: {
    async step() {
      if (this.step === 'processing') {
        await this.processImportJob()
      }
    },
    errors() {
      this.isErrorsModalActive = true
    },
  },
  async mounted() {
    this.linesProcessedTimestamp = Date.now()
    const pollImportStatus = async (): Promise<any> => {
      let status

      try {
        status = await this.getImportStatus()
      } catch (error) {
        return await pollImportStatus()
      }

      if (status.validationErrors.length) {
        this.errors = status.validationErrors
        this.isLoading = false
        return
      }

      if (
        this.step === 'validation' &&
        status.linesTotal > 0 &&
        status.linesValidated === status.linesTotal
      ) {
        this.step = 'processing'
        await pollImportStatus()
        return
      }

      const isOneMinuteAgo =
        this.linesProcessedTimestamp &&
        this.linesProcessedTimestamp < Date.now() - 2 * 60 * 1000

      if (isOneMinuteAgo) {
        this.isLoading = false
        const unprocessedLines = status.linesTotal - status.linesProcessed
        if (!this.errors) {
          this.errors = []
        }
        this.errors?.push({
          line: 0,
          errorMessage: `Processing took too long. ${unprocessedLines} lines were not processed.`,
        })
        return
      }

      if (this.linesProcessed !== status.linesProcessed) {
        this.linesProcessedTimestamp = Date.now()
        this.linesProcessed = status.linesProcessed
      }

      if (
        this.step === 'processing' &&
        status.linesTotal === status.linesProcessed
      ) {
        this.done = true
        this.isLoading = false
        return
      }

      await pollImportStatus()
    }

    await pollImportStatus()
  },
  methods: {
    async getImportStatus() {
      const currentUser = await getCurrentUser()
      const importId = this.$route.params.importId

      const { data } = await axios.get(
        `${process.env.VUE_APP_FULFILLMENT_ENDPOINT}/import/${importId}`,
        {
          headers: {
            Authorization: currentUser.signInUserSession.idToken.jwtToken,
          },
        }
      )

      return data
    },
    async processImportJob() {
      const currentUser = await getCurrentUser()
      const importId = this.$route.params.importId

      await axios.post(
        `${process.env.VUE_APP_FULFILLMENT_ENDPOINT}/import/${importId}`,
        {},
        {
          headers: {
            Authorization: currentUser.signInUserSession.idToken.jwtToken,
          },
        }
      )
    },
  },
})
